<template>
  <div id="app">
    <div class="header">
      <h5>
        <i id="back" class="fa fa-angle-left" @click="goBack()"></i>
        {{ $t("addTicketRule.newTicketRule") }}</h5>
    </div>
    <div class="container">
      <form autocomplete="off">
        <div class="input-field col s12">
          <select id="selectTicketType" v-model="ticketRule.ticketType">
            <option v-for="ticketType in this.ticketTypes" :key="ticketType.id" v-bind:value="ticketType.name">
              {{ $t("ticketType." + ticketType.name) }}
            </option>
          </select>
          <label for="selectTicketType" class="active">{{ $t("addTicketRule.ticketType") }}</label>
        </div>
        <div class="input-field col s12">
          <select id="selectStatus" v-model="ticketRule.status">
            <option v-for="status in statuses" :key="status">
              {{ $t("recordStatus." + status) }}
            </option>
          </select>
          <label for="selectTicketType" class="active">{{ $t("addTicketRule.ticketStatus") }}</label>
        </div>
        <div class="input-field col s12">
          <select id="assigned" v-model="ticketRule.assigned">
            <option v-for="user in admins" :key="user.id" v-bind:value="user.username">
              {{ user.username }}
            </option>
          </select>
          <label for="selectTicketType" class="active">{{ $t("addTicketRule.assigned") }}</label>
        </div>
        <div class="input-field col">
          <label for="comments">{{ $t("addTicketRule.comments") }}</label>
          <input id="comments" type="text" v-model="ticketRule.comments">
        </div>
        <p></p>
        <div id="controllers">
          <button v-on:click="createTicketRule" type="button" id="create" class="btn waves-effect waves-light">{{ $t("addTicketRule.create") }}<i class="fa fa-plus right"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Link from "../utils/Link";
import Vue from "vue";
import VueToast from "vue-toast-notification";

export default {
  data() {
    let data = {
      ticketTypes: {},
      ticketRule: {},
      admins: {},
      statuses: [
        "ACTIVE", "NOT_ACTIVE"
      ]
    };
    return this.initialization(data);
  },

  mounted() {
    let styleScript = document.createElement('script')
    styleScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js')
    document.head.appendChild(styleScript);
    let ajaxScript = document.createElement('script')
    ajaxScript.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js')
    document.head.appendChild(ajaxScript);
  },

  methods: {
    cancel() {
      this.getToast(false);
      return this.$router.push({name: 'tickets'});
    },

    initialization(data) {
      this.getTicketTypes().then(d => {
        data.ticketTypes = d;
      });
      this.getAdminUsers().then(admins => {
        data.admins = admins;
      });
      return data;
    },

    goBack() {
      return this.$router.back();
    },

    getTicketTypes() {
      let headers = Link.methods.getHeaders();
      return axios.get(Link.methods.getTicketTypesUrl(), {headers}).then(res => {
        return res.data;
      }).catch(() => {
        this.createToast(this.$t("addTicketRule.ticketTypesNotFound"), "error")
      });
    },

    getAdminUsers() {
      let headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getAllUsersUrl(),
          {headers}
      ).then(res => {
        if (res != null && res.data != null) {
          return res.data.filter((it) => it.role === 'ADMIN');
        } else {
          return []
        }
      }).catch(() => {
        this.createToast(this.$t("addTicketRule.usersNotFound"), "error")
      });
    },

    createTicketRule() {
      let headers = Link.methods.getHeaders();
      axios.post(
        Link.methods.getTicketRuleCreateUrl(),
        this.ticketRule,
        {headers}
      ).then(() => {
        this.$router.push('/ticketRules');
        this.createToast(this.$t("addTicketRule.createdToast"), "success");
      }).catch(() => {
        this.createToast(this.$t("addTicketRule.notCreatedToast"), "error");
      });
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 3000
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../../public/materialize-src/sass/materialize.scss';
@import "public/styles/vars.scss";
@import "public/styles/singleTicketRule";

.container {
  width: 300px;
}

#create {
  width: 100%;
  height: 40px;
  align-items: center;
  border: 1px;

  background: $second-color;
  border-radius: 4px;
}

select {
  width: 97%;
  padding: 0 20px;
  margin: 8px 1%;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

#controllers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>